<script setup lang="ts">
import {
	Input,
	FormMessage,
	FormItem,
	FormControl,
	FormLabel,
	FormField,
} from '@laam/ui/base';
import { PhInfo } from '@phosphor-icons/vue';

interface TextInput {
	type?:
		| 'text'
		| 'number'
		| 'password'
		| 'email'
		| 'date'
		| 'time'
		| 'datetime-local';
	label?: string;
	placeHolder: string;
	fieldName: string;
	description?: string;
	disabled?: boolean;
	upperCase?: boolean;
	hint?: Component;
	showErrorIcon?: boolean;
}

defineOptions({ inheritAttrs: false });
defineProps<TextInput>();
const emit = defineEmits(['focus', 'blur']);

const handleFocus = () => emit('focus');
const handleBlur = () => emit('blur');
</script>
<template>
	<FormField
		v-slot="{ componentField, errors, handleReset }"
		:name="fieldName"
		class="w-full"
	>
		<FormItem class="w-full">
			<div class="flex flex-col w-full gap-sm">
				<FormLabel v-if="label" class="text-sm font-medium text-gray-700">
					{{ label }}
				</FormLabel>
				<template v-if="hint">
					<component :is="hint" />
				</template>
				<FormControl>
					<Input
						:type="type"
						:upper-case="upperCase"
						:placeholder="placeHolder"
						v-bind="{ ...$attrs, ...componentField }"
						class="text_input w-full"
						:disabled="disabled"
						:data-testid="$attrs['data-testid']"
						:has-error="errors.length !== 0"
						@focus="handleFocus"
						@blur="handleBlur"
					>
						<template v-if="showErrorIcon && errors.length !== 0" #rightIcon>
							<PhInfo />
						</template>
					</Input>
					<template
						v-if="$slots.rightIcon && componentField.modelValue?.length > 0"
					>
						<slot name="rightIcon" :handle-reset="handleReset" />
					</template>
				</FormControl>
				<FormMessage class="text_input_error" />
			</div>
		</FormItem>
		<p v-if="!!description" class="text-sm text-gray-700 mt-sm">
			{{ description }}
		</p>
	</FormField>
</template>
